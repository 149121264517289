
import React, { useState, useEffect } from 'react';
import { Result, message } from 'antd';
import 'antd/lib/result/style/index.css'
import 'antd/lib/message/style/index.css'


import {
    TextField,
    Dialog, DialogActions,
    DialogContent, DialogTitle
} from "@material-ui/core"

import PhoneInput from "react-phone-number-input"

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import apiUrls from '../../api/apiUrl'


function AppoinmentBooking(props) {
    const [open, setopen] = useState(props.isOpen);
    const [isLogin, setisLogin] = useState(localStorage.getItem("userId") ? true : false);
    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [nameError, setnameError] = useState('');
    const [emailError, setemailError] = useState();
    const [phoneError, setphoneError] = useState();
    const [appoinmentSuccess, setappoinmentSuccess] = useState(false);
    const [otpVerification, setotpVerification] = useState(false);
    const [otp, setotp] = useState();
    const [otpError, setotpError] = useState();
    const [isSubmit, setisSubmit] = useState(false);
    const [isError, setisError] = useState(false);
    const [errMsg, seterrMsg] = useState('');

    const handleClose = () => {
        setopen(false)
    }


    const submitAppoinment = async () => {
        setisSubmit(true)
        try {
            var body = {
                Name: name,
                Email: email,
                Phone: phone

            }
            var response = await axios.post(apiUrls.createAppoinment, body)
            response?.data?.id && setappoinmentSuccess(true)
            // setTimeout(() => setisError(false), 1000)
            // setTimeout(() => setappoinmentSuccess(false), 9000)
        } catch (error) {
            console.log(error)
            setappoinmentSuccess(true)
            setisError(true)
            // setTimeout(() => setappoinmentSuccess(false), 9000)
            // setTimeout(() => setisError(false), 1000)
        } finally {
            setname('')
            setemail('')
            setphone('')
            setisSubmit(false)

        }
    }

    const sendOtp = async () => {
        setisSubmit(true)
        try {
            var response = await axios.post(apiUrls.appoinmentOtp, { phone: phone })
            if (response?.data?.status) {
                setotpVerification(true)
            }

            seterrMsg(response?.data?.message ?? 'Failed to sent Otp')
            setisSubmit(false)

        } catch (error) {
            console.log(error)
            seterrMsg(response?.data?.message ?? 'Failed to sent Otp')
            setisSubmit(false)

        }
    }

    const verifyOtp = async () => {
        setisSubmit(true)
        seterrMsg('')
        try {
            var response = await axios.post(apiUrls.appoinmentVerify, { phone: phone, otp: otp })
            if (response?.data?.status) {
                setisSubmit(false)
                setotpVerification(true)

                return submitAppoinment()
            }
            setotpError(response?.data?.message ?? 'Invalid Otp')
            setisSubmit(false)

        } catch (error) {
            console.log(error)
            seterrMsg(response?.data?.message ?? 'Failed to verify Otp')
            setisSubmit(false)

        }
    }


    const submitForm = () => {
        setnameError('')
        setemailError('')
        setphoneError('')
        if (!name) {
            setnameError('Name Required')
            return null
        }
        if (!email) {
            setemailError('Email Required')
            return null
        }
        if (!phone) {
            setphoneError('Phone Required')
            return null
        }
        var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        var phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

        if (!email.match(emailRegex)) {
            setemailError('Invalid email')
            return null
        }
        if (!phone.match(phoneRegex)) {
            setphoneError('Invalid phone')
            return null
        }

        if (isLogin) {
            submitAppoinment()
        } else {
            sendOtp()
        }


    }




    return <>

        <Dialog open={props.isOpen} onClose={props.handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <div className="row">
                    <div className="col-11">
                        Book appoinment

                    </div>
                    <div className="col-1">
                        <IconButton
                            disableFocusRipple={true}
                            onClick={props.handleClose}
                            aria-label="close"
                            edge={'end'}
                            style={{
                                border: "none",
                                outline: "none"
                            }}

                        >
                            <CloseIcon />
                        </IconButton>

                    </div>

                </div>




            </DialogTitle>
            {errMsg && <span className='text-center text-danger'>{errMsg}</span>}

            <DialogContent>
                {!appoinmentSuccess ? !otpVerification ? < div style={{ marginTop: "20px" }}>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Enter Your Full Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                        errorText={'required feild'}
                        required
                        helperText={nameError ? nameError : ""}
                        error={nameError}
                    />

                    <TextField
                        margin="dense"
                        id="name"
                        label="Enter Your Email Id"
                        type="text"
                        fullWidth
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        required
                        helperText={emailError ? emailError : ""}
                        error={emailError}

                    />

                    {/* <TextField
                        margin="dense"
                        id="name"
                        label="Enter Your Phone Number"
                        type="text"
                        fullWidth
                        type="number"
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                        required
                        helperText={phoneError ? phoneError : ""}
                        error={phoneError}

                    /> */}

                    <PhoneInput
                        // countryCallingCodeEditable={false}
                        international
                        className='w-100'
                        countryCallingCodeEditable={false}
                        defaultCountry="FR"
                        value={phone}
                        placeholder="Enter Phone Number"
                        onChange={e => {
                            setphone(e)
                        }}
                    />


                </div> :
                    <TextField
                        margin="dense"
                        id="Otp"
                        label="Enter OTP to Book Appoinment"
                        type="text"
                        fullWidth
                        type="number"
                        value={otp}
                        onChange={(e) => setotp(e.target.value)}
                        errorText={'required feild'}
                        required
                        helperText={otpError ? otpError : ""}
                        error={otpError}
                    />
                    : isError ? <Result
                        status="error"
                        title="Failed to save appoinment"
                        subTitle="Please tryagain later"

                    /> : <Result
                        status="success"
                        title="Appointment submitted successfully"
                        subTitle="Our experts will get back to you within the next 24 hours"

                    />}
            </DialogContent>
            <DialogActions>

                {!appoinmentSuccess ? otpVerification ?
                    <LoadingButton loading={isSubmit} variant="contained" style={{
                        border: "none",
                        outline: "none"
                    }} onClick={verifyOtp}>
                        Verify Otp
                    </LoadingButton> : <LoadingButton loading={isSubmit} variant="contained" style={{
                        border: "none",
                        outline: "none"
                    }} onClick={submitForm} >
                        Book
                    </LoadingButton> : ''}
            </DialogActions>
        </Dialog>



    </>
}

export default AppoinmentBooking;
